import { combineReducers } from 'redux';

/**
 * Ducks!
 */
import auth from './auth';
import settings from './settings';
import menu from './menu';
import ewallet from './ewallet';

/**
 * Combining reducers.
 */
export default combineReducers({
  auth,
  settings,
  menu,
  ewallet,
});
