import { createTypes, createReducer } from 'reduxsauce';

import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from '../../constants/defaultValues';

const INITIAL_STATE = {
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
};
/**
 * Action types.
 */
export const Types = createTypes(`
  MENU_CHANGE_HAS_SUB_ITEM_STATUS  
  MENU_CHANGE_DEFAULT_CLASSES
  MENU_CONTAINER_ADD_CLASSNAME
  MENU_CLICK_MOBILE_MENU
  MENU_SET_CLASSNAMES  
`);

const changeSelectedMenuHasSubItems = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedMenuHasSubItems: action.payload,
});

const changeDefaultClassnames = (state = INITIAL_STATE, action) => ({
  ...state,
  containerClassnames: action.strCurrentClasses,
});

const addContainerClassname = (state = INITIAL_STATE, action) => {
  const { classname, strCurrentClasses } = action;
  const newClasses =
    !strCurrentClasses.indexOf(classname) > -1
      ? `${strCurrentClasses} ${classname}`
      : strCurrentClasses;

  return {
    ...state,
    containerClassnames: newClasses,
  };
};

const clickOnMobileMenu = (state = INITIAL_STATE, action) => {
  const { strCurrentClasses } = action;
  const currentClasses = strCurrentClasses
    ? strCurrentClasses
        .split(' ')
        .filter((x) => x !== '' && x !== 'sub-show-temporary')
    : '';
  let nextClasses = '';
  if (currentClasses.includes('main-show-temporary')) {
    nextClasses = currentClasses
      .filter((x) => x !== 'main-show-temporary')
      .join(' ');
  } else {
    nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
  }

  return {
    ...state,
    containerClassnames: nextClasses,
    menuClickCount: 0,
  };
};

const setContainerClassnames = (state = INITIAL_STATE, action) => {
  const { strCurrentClasses, selectedMenuHasSubItems } = action;
  let { clickIndex } = action;

  const currentClasses = strCurrentClasses
    ? strCurrentClasses.split(' ').filter((x) => x !== '')
    : '';
  let nextClasses = '';
  if (!selectedMenuHasSubItems) {
    if (
      currentClasses.includes('menu-default') &&
      (clickIndex % 4 === 0 || clickIndex % 4 === 3)
    ) {
      clickIndex = 1;
    }
    if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
      clickIndex = 0;
    }
    if (
      currentClasses.includes('menu-hidden') &&
      (clickIndex % 4 === 2 || clickIndex % 4 === 3)
    ) {
      clickIndex = 0;
    }
  }

  if (clickIndex % 4 === 0) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden';
    }
    clickIndex = 0;
  } else if (clickIndex % 4 === 1) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  } else if (clickIndex % 4 === 2) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
    }
  } else if (clickIndex % 4 === 3) {
    if (
      currentClasses.includes('menu-default') &&
      currentClasses.includes('menu-sub-hidden')
    ) {
      nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  }
  if (currentClasses.includes('menu-mobile')) {
    nextClasses += ' menu-mobile';
  }

  return {
    ...state,
    containerClassnames: nextClasses,
    menuClickCount: clickIndex,
  };
};

/**
 * Reducers.
 */
export default createReducer(INITIAL_STATE, {
  [Types.MENU_CHANGE_HAS_SUB_ITEM_STATUS]: changeSelectedMenuHasSubItems,
  [Types.MENU_CHANGE_DEFAULT_CLASSES]: changeDefaultClassnames,
  [Types.MENU_CONTAINER_ADD_CLASSNAME]: addContainerClassname,
  [Types.MENU_CLICK_MOBILE_MENU]: clickOnMobileMenu,
  [Types.MENU_SET_CLASSNAMES]: setContainerClassnames,
});
