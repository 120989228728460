import { createTypes, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  user: {},
  token: null,
  register: null,
  loading: false,
  error: '',
};

/**
 * Action types.
 */
export const Types = createTypes(`
  UPDATE_USER
  SAVE_USER
  RESET_USER
`);

/**
 * Handlers.
 */

const updateUser = (state = INITIAL_STATE, action) => {
  const { email, mobilePhone, name, whosupId, surname } = action.user;
  return {
    ...state,
    user: { ...state.user, email, mobilePhone, name, whosupId, surname },
  };
};

// eslint-disable-next-line no-unused-vars
// const saveUser = (state = INITIAL_STATE, action) => action.user;
const saveUser = (state = INITIAL_STATE, action) => {
  const { email, mobilePhone, name, whosupId, surname, profilePic } =
    action.user.user;
  return {
    ...state,
    token: action.user.token,
    user: {
      email,
      mobilePhone,
      name,
      whosupId,
      surname,
      profilePic,
    },
  };
};

const reset = () => INITIAL_STATE;

/**
 * Reducers.
 */
export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_USER]: updateUser,
  [Types.SAVE_USER]: saveUser,
  [Types.RESET_USER]: reset,
});
