import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import reducers from './ducks';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
const persistor = persistStore(store);

const getPersistor = () => persistor;
const getStore = () => store;
const { dispatch } = store;
const getState = () => {
  return store.getState();
};
export { getStore, getState, getPersistor, dispatch };
export default {
  getStore,
  getState,
  getPersistor,
  dispatch,
};
