import { createTypes, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  extract: [],
  ewallet: { wupsAmount: 0 },
};

/**
 * Action types.
 */
export const Types = createTypes(`
  GET_EXTRACT
  UPDATE_EXTRACT
  UPDATE_EWALLET
  UPDATE_AMOUNT
  RESET_EWALLET
`);

/**
 * Handlers.
 */

// eslint-disable-next-line no-unused-vars
const getExtract = (state = INITIAL_STATE, action) => action.extract;

const updateExtract = (state = INITIAL_STATE, action) => ({
  ...state,
  extract: action.extract,
});

const updateEwallet = (state = INITIAL_STATE, action) => ({
  ...state,
  ewallet: action.ewallet,
});
const updateAmount = (state = INITIAL_STATE, action) => {
  const amount = Number(action.wups) + Number(state.ewallet.wupsAmount);
  return {
    ...state,
    ewallet: {
      wupsAmount: amount,
    },
  };
};

const reset = () => INITIAL_STATE;

/**
 * Reducers.
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_EXTRACT]: getExtract,
  [Types.UPDATE_EXTRACT]: updateExtract,
  [Types.UPDATE_EWALLET]: updateEwallet,
  [Types.UPDATE_AMOUNT]: updateAmount,
  [Types.RESET_EWALLET]: reset,
});
