import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
// import { configureStore } from './redux/store';
import { getStore, getPersistor } from './store';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <Provider store={getStore()}>
    <PersistGate loading={null} persistor={getPersistor()}>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
