import { createTypes, createReducer } from 'reduxsauce';
import { getCurrentLanguage, setCurrentLanguage } from '../../helpers/Utils';

export const INITIAL_STATE = {
  locale: getCurrentLanguage(),
};

/**
 * Action types.
 */
export const Types = createTypes(`
  CHANGE_LOCALE
`);

/**
 * Handlers.
 */
const changeLocate = (state = INITIAL_STATE, action) => {
  const newLocale = action.locale;
  setCurrentLanguage(newLocale);
  return {
    ...state,
    locale: newLocale,
  };
};

/**
 * Reducers.
 */
export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_LOCALE]: changeLocate,
});
